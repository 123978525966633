export const idmeLib = (getDocument) => {
  (function (window, document) {
    // Register remove() method for IE
    if (!('remove' in Element.prototype)) {
      Element.prototype.remove = function () {
        if (this.parentNode) {
          this.parentNode.removeChild(this);
        }
      };
    }

    // Query parameter management
    function Query(input) {
      this.input = input;

      this.fetch = function (key, defaultValue) {
        key = key.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        const input = `[\\?&]${key}=([^&#]*)`;
        const regex = new RegExp(input);
        const token = regex.exec(this.input);

        if (token == null) {
          return defaultValue !== undefined ? defaultValue : null;
        }
        return token[1];
      };
    }

    // function Popup(link, width, height, parentWidth, parentHeight) {
    //   let screenLeft  = window.screenLeft !== undefined ? window.screenLeft : window.screen.left;
    //   let screenTop   = window.screenTop !== undefined  ? window.screenTop  : window.screen.top;

    //   let left  = ((parentWidth / 2) - (width / 2)) + screenLeft;
    //   let top   = ((parentHeight / 2) - (height / 2)) + screenTop;

    //   let opened = window.open(link, "ID.me", 'scrollbars=yes, width=' + width + ', height=' + height + ', top=' + top + ', left=' + left);

    //   if (window.focus) opened.focus();
    // }

    // export modules
    if (window.IDme === undefined) window.IDme = {};

    const IDme1 = {
      // Popup: Popup,
      Query,
    };

    const idme_assets = 'https://s3.amazonaws.com/idme/developer/idme-buttons/';

    // import button.css
    const style = document.createElement('link');
    style.href = 'https://s3.amazonaws.com/idme/developer/idme-buttons/assets/css/unified/button.css';
    style.rel = 'stylesheet';
    style.text = 'text/css';

    document.head.appendChild(style);

    const DOMReady = (fn, context) => {
      function onReady(event) {
        document.removeEventListener('DOMContentLoaded', onReady);
        fn.call(context || window, event);
      }

      function onReadyIE(event) {
        if (document.readyState === 'complete') {
          document.detachEvent('onreadystatechange', onReadyIE);
          fn.call(context || window, event);
        }
      }

      (document.addEventListener && document.addEventListener('DOMContentLoaded', onReady)) ||
        (document.attachEvent && document.attachEvent('onreadystatechange', onReadyIE));
    };

    function BindIDme() {
      const button = getDocument.getElementById('idme-wallet-button');
      const scope = button.getAttribute('data-scope');
      const client = button.getAttribute('data-client-id');
      const redirect = button.getAttribute('data-redirect');
      const response = button.getAttribute('data-response');
      const state = button.getAttribute('data-state');
      const display = button.getAttribute('data-display');
      const logo = button.getAttribute('data-logo');
      const hero = button.getAttribute('data-hero');
      const text = button.getAttribute('data-text');
      const verify = button.getAttribute('data-show-verify');
      const hlp = button.getAttribute('data-hlp');
      const type = button.getAttribute('data-type');
      const country = button.getAttribute('data-country');
      const language = button.getAttribute('data-language');
      const multiple = scope.split(',').length > 1;

      // build widget parameters
      let params = '';

      if (scope && multiple) {
        params += `&scopes=${scope}`;
      } else {
        params += `&scope=${scope}`;
      }

      if (hlp) params += `&hlp=${hlp}`;
      if (hero) params += `&hero_url=${hero}`;
      if (logo) params += `&logo_url=${logo}`;
      if (state) params += `&state=${state}`;
      if (client) params += `&client_id=${client}`;
      if (redirect) params += `&redirect_uri=${redirect}`;
      if (response) params += `&response_type=${response}`;
      if (country) params += `&country=${country}`;
      if (language) params += `&language=${language}`;

      if (type) {
        params += `&type=${type}`;
      } else {
        params += '&type=button';
      }

      let buttonLink = null;

      if (hlp) {
        buttonLink = hlp;
      } else if (multiple) {
        buttonLink = `https://groups.id.me/?${params}`;
      } else {
        buttonLink = `https://api.id.me/oauth/authorize?${params}`;
      }

      const container = document.createElement('span');
      const trigger = document.createElement('div');
      const buttonElem = document.createElement('a');
      // var description = document.createElement("span");

      container.id = 'idme-verification';
      trigger.className = 'idme-trigger';
      buttonElem.className = 'idme-trigger-link idme-unify-button';
      // description.className = "idme-description"

      // description.innerHTML = 'Verification by ID.me â€¢ <a href="https://www.id.me/about" target="_new">What is ID.me?</a>'

      // display offer text if present
      if (text) {
        const offer = document.createElement('span');

        offer.className = 'idme-text';
        offer.innerHTML = text;

        trigger.appendChild(offer);
      }

      // toggle popup user experience
      if (display === 'popup') {
        // Popup(buttonLink, 775, 850, window.innerWidth, window.innerHeight);
        const link = buttonLink;
        const parentWidth = window.innerWidth;
        const parentHeight = window.innerHeight;
        const width = 775;
        const height = 850;
        const screenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screen.left;
        const screenTop = window.screenTop !== undefined ? window.screenTop : window.screen.top;

        const left = parentWidth / 2 - width / 2 + screenLeft;
        const top = parentHeight / 2 - height / 2 + screenTop;
        //  var domainUrl = window.location.host;
        //  var domainPath = window.location.pathname;

        const opened = window.open(link, 'ID.me', `scrollbars=yes, width=${width}, height=${height}, top=${top}, left=${left}`);
        // var timer = setInterval(function () {
        //    if (opened.closed) {
        //        window.clearInterval(timer);
        //        window.location.replace('https://' + domainUrl + domainPath + '?idmeclose=Y')
        //    }
        // }, 2000);
        if (window.focus) opened.focus();

        buttonElem.href = 'javascript:void(0);';
        // buttonElem.onclick = clickHandler;
      } else if (display === 'isMVA') {
        window.location.assign(buttonLink);
      } else {
        buttonElem.href = buttonLink;
        buttonElem.target = '_parent';
      }
      // buttonElem.innerHTML  = '<img src="' + idme_assets + 'assets/img/verify-with-idme.png" alt="ID.me Logo"/>';

      // let ctatextcolor="desktop-black mobile-black";

      // let xdatscope = document.getElementById("idme-wallet-button").getAttribute("data-scope");
      // if(xdatscope == 'teacher')
      // {
      //     ctatextcolor="desktop-white mobile-white";
      // }

      // buttonElem.innerHTML ='Confirm eligibility';
      // buttonElem.style.float="left";
      // buttonElem.style.backgroundColor="transparent";
      // buttonElem.className="button cta "+ctatextcolor+" mobile-xlarge-cta-text desktop-xlarge-cta-text border-all margin-bottom-zero";

      // trigger.appendChild(buttonElem);

      // display what is ID.me show verify true
      if (verify) {
        const description = document.createElement('span');

        description.className = 'idme-description';
        description.innerHTML = 'Verification by ID.me <a href="https://www.id.me/about" target="_new">What is ID.me?</a>';

        trigger.appendChild(description);
      }
      const idmetrigger = getDocument.querySelectorAll('.idme-trigger');
      if (idmetrigger !== null && idmetrigger[0] != null && !container.getElementById('idme-verification').contains(idmetrigger[0]))
        container.appendChild(trigger);

      // insert after widget
      button.parentNode.insertBefore(container, button.nextSibling);

      // remove widget
      // button.remove();
    }
    window.BindIDme = BindIDme;
    window.DOMReady = DOMReady;
  })(window, document);

  if (window.location.search.indexOf('CMP') > -1 && window.location.search.indexOf('c') > -1 && window.location.search.indexOf('abr') > -1) {
    window.DOMReady(window.setTimeout(window.BindIDme, 2000));
  } else if (navigator.userAgent.indexOf('Firefox') != -1) {
    window.DOMReady(window.BindIDme());
  } else {
    window.DOMReady(window.BindIDme());
  }
};
