import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ModalBody, ModalTitle } from '@vds/modals';
import styled from 'styled-components';
import { getResponsiveLQ, getSourceInfo } from '../../config';
import Loader from '../common/loader/Loader';

import { getCurrentBaseView } from '../loop-qual-app/view-resolver';
import { Spacing } from '../home-lq/styles';

const ModalTitleThreeDotO = styled.div`
  ${(props) =>
    `font-size: 2rem;
  line-height: 2.25rem;
  font-weight: 300;
  font-family: Verizon-NHG-eDS;
  padding: 0px 0px 0px ${props.homeLQ ? 0 : 4}px;
  ${
    props.homeLQ
      ? `
  @media screen and (max-width: 767px) {
  padding-right: 44px;
  }
  `
      : ``
  }
  `}
`;
const ModalShadowContent = (props) => {
  console.log('...props modaloverlay', props);
  const selector = useSelector((state) => state);
  const loaderStatus = selector?.loader?.loaderStatus;
  const loopQualApp = selector?.loopQualApp;
  const homeLQ = loopQualApp?.homeLQ;
  const modalOverlay = selector?.modalOverlay;
  const modalTitle = props.modalTitle || modalOverlay.modalTitle || '';
  const isVfEmailFlow = modalTitle.indexOf('Verizon Forward discount.') > -1;
  const currentBaseView = getCurrentBaseView(loopQualApp.sessionTimeoutView || loopQualApp.currentLQView);
  const params1 = new URLSearchParams(window.location.search);
  const isResponsiveLQMini = getResponsiveLQ() === 'Y';
  const isResponsiveLQ = getSourceInfo() === 'ResponsiveLQ' || isResponsiveLQMini || params1.get('type') === 'ResponsiveLQ';
  const isSkeletonView = loopQualApp?.showSkeleton && !isResponsiveLQ;
  const modalContent = loopQualApp?.homeLQ ? (
    getCurrentBaseView(loopQualApp.homeLQView, {}, true)
  ) : (
    <>
      {currentBaseView}
      <Loader active={loaderStatus || isSkeletonView} />
    </>
  );
  return (
    <>
      <ModalTitle primitive="h2">
        <ModalTitleThreeDotO homeLQ={homeLQ}>
          <div id="modalTitleHeading" aria-label={modalTitle} className="modalTitleHeading" style={{ fontWeight: isVfEmailFlow && '300' }}>
            {modalTitle}
          </div>
        </ModalTitleThreeDotO>
      </ModalTitle>
      {props.addressView}
      <ModalBody>
        {homeLQ && props.addressView ? (
          <Spacing mobilePadding={`${homeLQ?.spacing?.xxLarge} 0px 0px 0px`} padding={`${homeLQ?.spacing?.xxLarge} 0px 0px 0px`}>
            {modalContent}
          </Spacing>
        ) : (
          modalContent
        )}
      </ModalBody>
    </>
  );
};

export default ModalShadowContent;

ModalShadowContent.propTypes = {
  addressView: PropTypes.any,
  modalTitle: PropTypes.any,
};
