import styled from 'styled-components';

export const ShadowStyleWrapper = styled.div`
  .menu-list-item {
    border-bottom: 0.0625rem solid #ccc;
    border-bottom-width: 0.0625rem;
    border-bottom-style: solid;
    border-bottom-color: rgb(204, 204, 204);
    background-color: #fff;
  }

  .menu-list-item a {
    font-size: 0.875rem;
    color: #000;
    padding: 0.625rem 0.9375rem 0.625rem 0.5rem;
    display: block;
    border-left: 0.375rem solid #fff;
    text-decoration: none;
  }
  .menu-list-item a:hover,
  .menu-list-item a.item-highlighted {
    border-left-color: #cd040b;
    background-color: #f6f6f6;
    text-decoration: none;
  }
  .menu-item {
    position: inherit;
    max-height: 10rem;
    border: 0.0625rem solid #ccc;
  }
  .loop-qualification .reskin-menu-list-item {
    font-family: Verizon-NHG-eTX, Helvetica, Ariel, sans-serif;
    font-weight: 400;
    line-height: 1.6;
  }
  .reskin-menu-list-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px;
    background-color: #f3f3f5;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    font-style: normal;
    font-size: 14px;
  }
  .reskin-menu-list-item a {
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    font-size: 0.875rem;
    color: #000;
    letter-spacing: 0.5px;
    text-decoration: none;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2.5px 19px;
    gap: 6px;
    font-weight: 50 !important;
  }
  .reskin-menu-list-item a:hover,
  .reskin-menu-list-item a.item-highlighted {
    background-color: #6092ed;
    border-radius: 4px;
  }
  .reskin-menu-item {
    position: inherit;
    max-height: 10rem;
    border: 0.0625rem solid #ccc;
  }
  &#lq20 #ac-reskin-wrapper:focus-within #reskin-internal-wrapper::before {
    border: 1px dashed #000000;
    content: '';
    left: 50%;
    border-radius: 0.375rem;
    position: absolute;
    height: calc(100% + 0.375rem);
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% + 0.375rem);
  }
  &#lq20 .modalTitleHeading:focus {
    outline: 1px dashed #000000;
    border-radius: 0.375rem;
  }
  /*.js-focus-visible :focus.focus-visible, .js-focus-visible :focus[data-focus-visible-added] {
    outline: none !important;
    }
#internal-wrapper > input.focus-visible {
    outline: none !important;
    }
#internal-wrapper-unit  > input.focus-visible {
    outline: none !important;
    }    
#reskin-internal-wrapper > input.focus-visible {
        outline: none !important;
    }  */
  .autocompleteInput {
    font-family: Verizon-NHG-eTX, Helvetica, Ariel, sans-serif;
    letter-spacing: 0.5px;
    margin-bottom: 0px;
    margin-top: 0px;
    font-weight: 400;
    font-size: 1rem;
    padding: 0.5rem 0.625rem;
    height: 3rem;
    width: 100%;
  }
  .autocompleteInput.light {
    border: 1px solid #6f7171;
    outline-offset: 1px;
  }
  .autocompleteInput.light:focus {
    /* border: 1px solid transparent; */
    outline: 1px dashed #000 !important;
  }

  #uniterror:focus {
    outline: 1px dashed #000 !important;
  }
  #support-drawer {
    background-color: #000;
    border: none;
    padding: 0;
  }
  #support-drawer:focus {
    outline-style: dotted;
    outline-color: #fff;
  }
  #support-drawer.light {
    background-color: transparent;
    border: none;
    padding: 0;
  }
  #support-drawer.light:focus {
    /* outline-color: #000; */
    border: 1px dotted #000;
  }
  .autocompleteInputError {
    background-color: rgb(251, 228, 215) !important;
    border: 0.0625rem solid rgb(196, 73, 4) !important;
  }
  .autocompleteInputError.light {
    border: 1px solid rgb(196, 73, 4) !important;
  }
  .autocompleteInput.autocompleteInputError.light:focus {
    /* border: 1px solid transparent !important; */
    /* outline: 1px dashed rgb(196, 73, 4) !important; */
  }
  .autocompleteInput:focus {
    background-color: #fff;
    border-left-color: #4b4b4b;
    border-right-color: #4b4b4b;
    border-top-color: #4b4b4b;
    outline: 1px dashed #fff !important;
    color: #333;
  }
  input,
  textarea {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    background-color: #fff;
    border: 0.0625rem solid #000000;
    border-radius: 4px;
    color: #000000;
    display: block;
    margin: 0 0 1.5rem;
    outline: 0 !important;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .loop-qualification label {
    font-size: 0.75rem;
    font-weight: 400;
    color: #000;
    text-decoration: none;
    margin: 0;
    font-family: Verizon-NHG-eTX, Helvetica, Ariel, sans-serif;
  }
  .minilq input.address-autocomplete {
    width: 440px !important;
  }

  @media (min-width: 768px) {
    input.address-autocomplete + div {
      position: absolute;
      top: auto !important;
      left: auto !important;
      z-index: 1100;
    }
  }

  /*LOOP QUAL CONTROL TEMP. NEED CLEAN UP*/
  .loop-qualification {
    height: 100%;
    width: 100% !important;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
  }
  .address-input-height {
    height: auto;
  }

  .minilq .address-input-height {
    height: 64px;
  }

  @media only screen and (max-width: 1023px) {
    .minilq .address-input-height {
      height: auto;
    }
  }

  #expand-contract .loop-qualification {
    margin-top: 0px;
  }
  .loop-qualification > .full-width {
    width: 100% !important;
  }
  .loop-qualification > .restricted-width {
    width: 50% !important;
  }
  .loop-qualification > .restricted-width-reskin {
    width: 100%;
  }
  #expand-contract .loop-qualification > .restricted-width-reskin {
    padding-left: 16px;
  }
  .loop-qualification > .restricted-width-reskin p {
    height: 16px;
  }

  .loop-qualification > .restricted-width div:first-child {
    display: block;
  }

  @media only screen and (max-width: 768px) {
    .loop-qualification > .restricted-width {
      width: 60% !important;
    }
  }
  @media only screen and (max-width: 768px) {
    .loop-qualification > .restricted-width-reskin {
      width: 100%;
    }

    #expand-contract .loop-qualification > .restricted-width-reskin {
      padding-left: 2px;
    }
  }

  .loop-qualification input.as-select {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 8'%3E%3Cpath d='M5.5 5.5l-.354.354.354.353.354-.353L5.5 5.5zM-.354.354l5.5 5.5.708-.708-5.5-5.5-.708.708zm6.208 5.5l5.5-5.5-.708-.708-5.5 5.5.708.708z'/%3E%3C/svg%3E") !important;
    background-position: right 0.875rem center !important;
    background-repeat: no-repeat;
    background-size: 1.125rem;
  }

  .optional-label {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    color: rgb(116, 118, 118);
    content: ' Optional';
    display: inline;
  }
  @media only screen and (max-width: 80rem) {
    /* line 41, ../../scss/app/loopQualification.scss */
    .loop-qualification input,
    .loop-qualification select,
    .loop-qualification select option {
      white-space: nowrap;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      max-width: 100%;
      padding-right: 40px;
    }
  }
  @media only screen and (max-width: 767px) {
    .minilq input.address-autocomplete,
    .minilq input#apartmentNumber,
    .minilq input.address-autocomplete #Addressoptions,
    .minilq input.address-autocomplete + div {
      width: 380px !important;
    }
    .AddressParent {
      width: 380px !important;
    }
    .AddressParent > div {
      padding-right: 0 !important;
    }
  }
  @media only screen and (max-width: 430px) {
    .AddressParent {
      width: 100% !important;
    }
  }

  /* ipad media */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .minilq input.address-autocomplete,
    .minilq input.address-autocomplete + div,
    .minilq input.address-autocomplete #Addressoptions {
      width: 294px !important;
    }
    .minilq input#apartmentNumber,
    .minilq input.input-as-text,
    .minilq input.input-as-text + div {
      width: 190px !important;
    }
  }

  /* surface Dou*/
  @media only screen and (min-device-width: 540px) and (max-device-width: 720px) {
    .minilq #geoLocationText {
      padding-right: 25%;
    }
  }

  /* line 53, ../../scss/app/loopQualification.scss */
  .loop-qualification_left {
    background: #000000;
  }

  @media only screen and (max-width: 30.0625em) {
    /* line 53, ../../scss/app/loopQualification.scss */
    .loop-qualification_left {
      padding: 1.25rem 1.25rem !important;
      position: fixed;
      z-index: 100;
      top: 0;
      display: none;
    }
  }

  @media only screen and (max-width: 33rem) {
    .loop-qualification_left-inner > div > h5 > br {
      display: none;
    }

    .loop-qualification_left-inner > div > h5 {
      padding-top: 0.4rem;
      padding-bottom: 0.4rem;
    }
  }

  .loop-qualification_left-inner > div > h5 {
    line-height: 1;
  }

  .CA .loop-qualification_right .loop-qualification_address-field #addressErr {
    margin-bottom: 0;
  }

  .loop-qualification_left-inner {
    position: relative;
  }
  .loop-qualification_right {
    padding: 0rem 0 0 2.5rem;
    vertical-align: top;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  @media only screen and (max-width: 30.0625em) {
    .loop-qualification_right {
      height: calc(100vh) !important;
      overflow: auto;
    }
    .device-ios.device-mobile .loop-qualification_right {
      height: auto !important;
    }
  }
  @media only screen and (min-height: 75rem) {
    .inherit-height {
      height: 23vh;
      overflow: auto;
    }
    .overlay-address-section .loop-qualification_left {
      padding-top: 10vh;
    }
  }
  .loop-qualification_left-inner > div > h5.text-black {
    font-size: 1.25rem !important;
  }
  @media only screen and (max-width: 47.9375rem) {
    .loop-qualification_right.m_loop-qualification_right-space {
      margin-top: 0rem;
    }
  }
  .loop-qualification_right .loop-qualification_address-field {
    padding-right: 2.5rem;
    padding-top: 2.5rem;
  }

  @media only screen and (max-width: 47.9375rem) {
    .loop-qualification_right .loop-qualification_address-field {
      padding-right: 1.25rem;
      margin-top: 3.75rem;
    }
  }
  @media only screen and (min-width: 48rem) {
    .loop-qualification_right .loop-qualification_address-field {
      margin-top: unset !important;
    }
  }
  @media only screen and (max-width: 47.9375rem) {
    .loop-qualification_right.m_loop-qualification-top {
      margin-top: 5.9375rem;
      padding-right: 1.25rem !important;
    }
  }
  .loop-qualification_address {
    height: 90%;
    width: 100% !important;
  }
  .loop-qualification_address.success {
    height: auto;
  }
  @media only screen and (min-width: 60.625rem) {
    .loop-qualification_address.success {
      width: 80% !important;
      vertical-align: initial;
      height: auto;
    }
  }
  @media only screen and (min-width: 48rem) {
    .loop-qualification_address input + div {
      position: absolute;
      top: initial !important;
      left: initial !important;
      z-index: 1000;
      min-width: 100% !important;
    }
  }
  @media only screen and (max-width: 47.9375rem) {
    .loop-qualification_request-service {
      margin-top: 4.0625rem !important;
    }
  }
  @media only screen and (max-width: 80rem) {
    .loop-qualification_or-wrap {
      padding-left: 0;
      padding-right: 0;
    }
  }
  @media only screen and (min-width: 80.0625rem) {
    .loop-qualification_or-wrap {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .loop-qualification_or {
    position: relative;
  }
  .loop-qualification_or {
    border-top: 0.0625rem solid #ccc;
    margin-top: 0.6rem;
    margin-bottom: 1.7rem;
  }
  .loop-qualification_or span {
    position: absolute;
    top: 1.875rem;
    background: #fff;
    left: -0.375rem;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
  }
  @media only screen and (max-width: 47.9375rem) {
    .loop-qualification_or span {
      padding-left: 0.3125rem;
      padding-right: 0.3125rem;
      left: calc(50% - 12px);
      top: -1.0625rem;
    }
    .loop-qual-hide-mobile {
      display: none;
    }
  }
  .input-as-select {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 8'%3E%3Cpath d='M5.5 5.5l-.354.354.354.353.354-.353L5.5 5.5zM-.354.354l5.5 5.5.708-.708-5.5-5.5-.708.708zm6.208 5.5l5.5-5.5-.708-.708-5.5 5.5.708.708z'/%3E%3C/svg%3E") !important;
    background-position: right 0.4rem top 54%;
    background-repeat: no-repeat;
    background-size: 1.125rem;
  }
  .emailLink a,
  .emailLink a:visited {
    color: black;
    outline: none;
    text-decoration: underline;
  }
  @media screen and (min-width: 768px) {
    /* non-overlay flow buttons to have auto width for desktop res alone*/
    .button {
      width: auto !important;
      /* padding: 12px 60px !important; */
    }
  }
  @media only screen and (max-width: 80rem) {
    .button {
      width: auto !important;
      /* padding: 12px 60px !important; */
    }
  }
  @media (min-width: 767px) {
    .fifHed {
      line-height: 1.05rem;
      font-size: 0.875rem;
      font-family: BrandFont-Text;
      flex: 1 0 auto;
      color: #000000;
      letter-spacing: 0;
      padding: 0px;
      position: relative;
      padding-top: 20px;
    }
  }
  input#apartmentNumber.input-as-select {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }

  .minilq #apartmentNumber {
    width: 225px;
    height: 44px;
  }

  .minilq #streetaddress {
    height: 44px;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .minilq #address-edit-wrapper {
      display: flex;
    }
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (max-device-height: 1020) and (orientation: portrait) {
    .address-height {
      height: 16px;
    }
  }
  .minilq #helpIcon {
    display: inline;
    height: 0px;
    width: 13px;
    min-height: 13px;
    min-width: 13px;
  }

  #location.light {
    outline-color: #000 !important;
  }

  #streetInputText,
  #appartment-unit {
    min-height: 20px;
    display: inline-block;
  }
  body {
    margin: 0;
  }
  .fiveGHeader {
    font-size: 1.4rem;
    line-height: 1.6rem;
    /* margin:0px 0px 14px; */
    color: black;
    /* margin-bottom:.875rem; */
    display: block;
    font-family: BrandFont-Display, Helvetica, Arial, sans-serif;
    letter-spacing: -0.9px;
    font-weight: normal;
  }
  .subHeader {
    font-size: 14px;
    line-height: 1.5;
    margin: 0;
    font-family: BrandFont-Display, Helvetica, Arial, sans-serif;
    margin-bottom: 40px !important;
    font-weight: normal;
  }
  &#lq20 #lqw-modal-container .loader {
    right: initial;
    bottom: initial;
  }
  @media screen and (min-width: 991px) {
    .fiveGHeader {
      font-size: 1.4rem;
      line-height: 1.6rem;
      /* margin:0px 0px 14px; */
      color: black;
      /* margin-bottom:.875rem; */
      display: block;
      font-family: BrandFont-Display, Helvetica, Arial, sans-serif;
      letter-spacing: -0.9px;
      font-weight: normal;
    }
  }
  .serviceunavailable {
    width: 100%;
  }
  @media screen and (min-width: 991px) {
    .serviceunavailable {
      width: 62%;
    }
  }
  .hide {
    display: none;
  }

  /*body.hide-focus-outline *:focus {
    outline: none !important;
    box-shadow: none !important;
}*/
  /*override dotted focus ring on IE/FF and apply same style for all*/
  /* body:not(.hide-focus-outline) *:focus {
    outline: none !important;
    box-shadow: 0px 0px 0.3rem 0.25rem #8ad4f7 !important;
} */
  .arrow {
    color: rgb(252, 246, 246) !important;
    font-family: monospace !important;
    font-size: xxx-large !important;
    float: right;
    margin-right: 0.8em;
  }
  .plansTextStyle {
    list-style: none;
    display: flex;
    padding-bottom: 8px;
  }
  .plansList {
    padding-left: 6px;
  }
  .fiosOnlyHyperLinkQues {
    margin-top: 15px;
    display: inline-block;
  }
  @media (min-width: 767px) {
    .modalHeight {
      max-height: 90vh !important;
    }
  }
  .brurbl {
    padding: 0;
  }
  &#lq20 label.jaMOWf {
    margin-bottom: 12px;
  }
  .mapShow {
    opacity: 0;
    height: 10px;
    overflow: hidden;
  }
  #expand-contract.expanded {
    content-visibility: visible;
    display: flex;
    align-items: normal;
  }
  #expand-contract.collapsed {
    content-visibility: hidden;
    display: none;
  }
  @media (max-width: 767px) {
    #expand-contract.expanded {
      width: 100%;
      flex-wrap: wrap;
      /* padding-top: 5px; */
      /* gap: 24px; */
      /* height: inherit; */
      margin-top: 16px;
    }
    .address-input-height:has(#apartmentNumber) {
      padding-top: 16px;
    }
    .expanded-unit-address:has(#appartment-unit-errorText) {
      /* padding-bottom: 16px; */
    }
  }
  @media (min-width: 768px) {
    #expand-contract {
      padding-right: 32px;
    }
    #expand-contract.expanded {
      margin-top: -12px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    #expand-contract.expanded {
      margin-top: 0px;
    }
  }
  input#apartmentNumber.input-as-select + div,
  input#apartmentNumber + div {
    position: absolute;
    top: auto !important;
    left: auto !important;
    z-index: 1100;
  }
  input#streetaddress + div {
    position: absolute;
    top: auto !important;
    left: auto !important;
    z-index: 1100;
    border-bottom: none !important;
    border-width: 0px !important;
  }
  #footerexp input#streetaddress + div,
  #footerexp #apartmentNumber + div {
    bottom: 53px;
  }

  #headFoot #two-col-wrapper div:nth-child(2) {
    width: 50% !important;
  }
  .modalInputWrapper input {
    margin-bottom: 0px;
    max-width: 100%;
    border: 0;
    background: transparent;
  }
  /* &#lq20 h3, &#lq20 h4, &#lq20 h5, &#lq20 h6, &#lq20 label, &#lq20 p,*/
  &#lq20 section,
  &#lq20 div,
  &#lq20 ul,
  &#lq20 li,
  &#lq20 ol,
  &#lq20 select,
  &#lq20 input {
    margin-bottom: 0px;
    max-width: 100%;
  }
  &#lq20 input:focus:not([readonly]),
  &#lq20 input.value:focus:not([readonly]) {
    background-color: transparent;
  }
  &#lq20 input.value,
  &#lq20 input:focus {
    background-color: transparent;
  }
  &#lq20 input[type='radio']:checked + label:before,
  &#lq20 input[type='radio'] + label::before {
    display: none;
  }
  &#lq20 input[type='radio'] + label {
    padding: 0px 0px 0px 0px;
  }
  &#lq20 input:focus {
    outline: none !important;
  }
  .vz5gh-radio-button input[type='radio']:checked + label::before,
  .vz5gh-radio-button input[type='radio']:checked:hover + label::before,
  .vz5gh-radio-button input[type='radio'] + label::before,
  .vz5gh-radio-button input[type='radio']:hover + label::before {
    content: none;
  }
  .vz5gh-radio-button input[type='radio'] + label {
    display: flex;
    padding: 0 0 0 16px;
  }
  .vz5gh-radio-button .radioWrapper {
    margin: 0 !important;
    padding: 0.2rem 0 0.375rem 0.6rem;
  }
  .vz5gh-radio-button .radioWrapper {
    margin: 0 0 4px 0 !important;
  }
  .vz5gh-radio-inservice .radioWrapper {
    margin: 0 !important;
    padding: 0rem 0 0.375rem 0.6rem;
  }
  .VFQuestionaire .vz5gh-radio-inservice .radioWrapper {
    padding: 0 0 16px 16px !important;
  }
  .VFQuestionaire button {
    margin-top: 16px;
  }
  .vz5gh-radio-box input[type='radio']:checked + label::before,
  .vz5gh-radio-box input[type='radio']:checked:hover + label::before,
  .vz5gh-radio-box input[type='radio'] + label::before,
  .vz5gh-radio-box input[type='radio']:hover + label::before {
    content: none;
  }
  .vz5gh-radio-box input[type='radio'] + label {
    font-size: 0.875rem;
    color: #333;
    cursor: pointer;
    display: block;
    font-weight: bold;
    margin-bottom: 0.1875rem;
    line-height: 1.5;
    display: inline-block;
    margin: 0 0 0 0;
    position: relative;
    text-decoration: none;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  /* Once the new version of VDS module installed & contains modal body padding issue resolved 
then Please remove this below CSS code*/
  .lQModalOverlay div[data-testid='modal-body'] {
    padding: 12px 0 0;
  }

  .VFQuestionaire p {
    font-size: 11px;
  }

  .lQModalOverlay div[data-testid='modal-body']:has(.VFQuestionaire) {
    padding: 32px 0 0;
  }

  /* Added code to remove optinal label from VDS input box */
  .restricted-width span::after {
    display: none;
  }
  .restricted-width-reskin span::after {
    display: none;
  }
  #accordionHelpSection button.accordionButton {
    outline-style: none !important;
    background-color: unset;
  }
  a#edit-address {
    color: white !important;
    border-bottom-style: solid;
    border-bottom-color: white;
  }
  a#edit-address:focus {
    outline: dotted;
    outline-color: white;
  }

  &#lq20 iframe.signin {
    width: 400px;
    margin: 0px auto;
    padding-top: 1rem;
    display: block;
    border: 0px;
    min-height: 600px;
  }
  .radioOuter {
    width: 18px !important;
    height: 18px !important;
  }

  .H_overlay .H_btn {
    background: transparent;
  }
  .H_ctl.H_el.H_scalebar {
    display: none;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    .bHtmtu {
      width: 92%;
    }
    body .lqWidgetComp .minilq:not(#streetaddress) {
      height: auto !important;
      padding-bottom: 0px;
    }
    body .lqWidgetComp .minilq:has(#streetaddress) {
      height: auto !important;
      transition: 0.4s;
      padding-bottom: 0px;
    }
    body .lqWidgetComp .minilq:has(#appartment-unit) {
      height: auto !important;
    }
    body .lqWidgetComp .minilq:has(#streeError) {
      height: auto !important;
    }
    body .lqWidgetComp .minilq:has(#appartment-unit-errorText) {
      height: auto !important;
    }
    body .root[data-current-lq-view] .lqWidgetComp .minilq:has(#streetaddress) {
      height: auto !important;
      transition: 0.4s;
    }
    body .root[data-current-lq-view] .lqWidgetComp .minilq:has(#appartment-unit) {
      height: auto !important;
    }
    body .root[data-current-lq-view] .lqWidgetComp .minilq:has(#streeError) {
      height: auto !important;
    }
    body .root[data-current-lq-view] .lqWidgetComp .minilq:has(#appartment-unit-errorText) {
      height: auto !important;
    }
    body .lqWidgetComp .minilq:has(.collapsed),
    body .lqWidgetComp .minilq:has(#appartment-unit):has(.collapsed),
    body .lqWidgetComp .minilq:has(#appartment-unit-errorText):has(.collapsed) {
      height: 130px !important;
    }
    body .root[data-current-lq-view] .lqWidgetComp .minilq:has(.collapsed),
    body .root[data-current-lq-view] .lqWidgetComp .minilq:has(#appartment-unit):has(.collapsed),
    body .root[data-current-lq-view] .lqWidgetComp .minilq:has(#appartment-unit-errorText):has(.collapsed) {
      height: 130px !important;
    }

    .lQModalOverlay div[data-testid='modal-body']:has(.VFQuestionaire) {
      padding: 24px 0 0;
    }
    #streeError {
      /* margin-top: 8px; */
    }
    .loop-qualification:has(#streeError) {
      /* margin-bottom: 12px; */
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .minilq:has(#streeError) {
      /* padding-bottom: 25px; */
    }
  }
  .stickyfocus {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    z-index: 80;
    top: 0px;
  }
  /* @media (min-width: 1024px) {
  .minilq {
    height: 112px;
    transition: 0.4s;
  }
  .minilq:has(.autocompleteInputError),
  .minilq:has(#appartment-unit-errorText) {
    height: 120px;
  }
  .minilq:has(#apartmentSelctDropdown) {
    height: 120px;
  }
} */
  .right-minlq-wrapper {
    display: flex;
    @media (min-width: 1024px) {
      padding-left: 45px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .minilq {
      height: 173px !important;
      transition: 0.4s;
    }
    .minilq:has(.autocompleteInputError),
    .minilq:has(#appartment-unit-errorText) {
      height: 197px !important;
    }
    .minilq:has(#apartmentSelctDropdown) {
      height: 197px !important;
    }
    .root[data-current-lq-view] .minilq {
      height: 173px !important;
      transition: 0.4s;
    }
    .root[data-current-lq-view] .minilq:has(.autocompleteInputError),
    .root[data-current-lq-view] .minilq:has(#appartment-unit-errorText) {
      height: 197px !important;
    }
    .root[data-current-lq-view] .minilq:has(#apartmentSelctDropdown) {
      height: 197px !important;
    }
    .right-minlq-wrapper {
      justify-content: flex-start;
    }
    .right-minlq-wrapper {
      /* justify-content: flex-start; */
      width: 100%;
    }
    .right-minlq-wrapper #expand-contract {
      /* justify-content: flex-start; */
      /* width: 100%; */
      padding-right: 10px;
      flex: 1;
    }
    .right-minlq-wrapper #expand-contract .loop-qualification {
      padding-right: 20px;
    }
    .right-minlq-wrapper #expand-contract input {
      width: 100% !important;
    }
  }
  @media (max-width: 767px) {
    .right-minlq-wrapper {
      flex-direction: column;
    }
  }
  @media (min-width: 1024px) {
    .minilq {
      height: 112px !important;
      transition: 0.4s;
    }
    .minilq:has(.autocompleteInputError),
    .minilq:has(#appartment-unit-errorText) {
      height: 120px !important;
    }
    .minilq:has(#apartmentSelctDropdown) {
      height: 120px !important;
    }
    body .root[data-current-lq-view] .lqWidgetComp .minilq {
      height: 112px !important;
      transition: 0.4s;
    }
    .minilq.interchange_sticky {
      min-height: 82px !important;
      max-height: 106px !important;
      height: auto !important;
      transition: 0.4s;
    }
    body .root[data-current-lq-view] .lqWidgetComp .minilq.interchange_sticky {
      min-height: 82px !important;
      max-height: 106px !important;
      height: auto !important;
      transition: 0.4s;
    }
    body .root[data-current-lq-view] .lqWidgetComp .minilq:has(.autocompleteInputError),
    body .root[data-current-lq-view] .lqWidgetComp .minilq:has(#appartment-unit-errorText) {
      height: 120px !important;
    }
    body .root[data-current-lq-view] .lqWidgetComp .minilq:has(#apartmentSelctDropdown) {
      height: 120px !important;
    }
  }
  span#Mobile-phone-number-label,
  span#Email-address-field-label {
    display: none;
  }
  /* @media (min-width: 1020px) {
  .minilq,
  .minilq:has(.autocompleteInputError),
  .minilq:has(#appartment-unit-errorText),
  .minilq:has(#apartmentSelctDropdown) {
    height: 112px;
  }
} */

  @media (min-width: 1024px) {
    .minilq.interchange_sticky #left-content {
      padding-bottom: 1rem;
      padding-top: 1rem;
    }
    .minilq #left-content {
      padding-top: 32px;
      padding-bottom: 32px;
    }
    .minilq #expand-contract:has(#apartmentNumber) #streetaddress {
      width: 281px !important;
      min-width: 100%;
      max-width: 100%;
    }
    .minilq #expand-contract input.address-autocomplete + div {
      min-width: 440px !important;
      min-width: 100%;
      max-width: 100%;
    }
    .minilq #expand-contract:has(.input-as-select) .input-as-select,
    input#apartmentNumber.input-as-select + div {
      width: 196px;
    }
    .minilq #expand-contract:has(.input-as-text) .input-as-text,
    .minilq input#apartmentNumber.input-as-text + div {
      width: 212px;
      min-width: 100%;
      max-width: 100%;
    }
    .minilq #expand-contract:has(#apartmentSelctDropdown) .loop-qualification #reskin-internal-wrapper,
    .minilq #expand-contract:has(#apartmentSelctDropdown) #appartment-unit-errorText {
      padding-bottom: 0px;
    }

    #expand-contract .loop-qualification #geoLocationText,
    #expand-contract .loop-qualification #streetInputText,
    #expand-contract .loop-qualification #appartment-unit,
    .minilq .getUpdates {
      /* padding-top: 24px !important; */
    }
    #expand-contract .loop-qualification #reskin-internal-wrapper,
    .minilq .getUpdates {
      padding-bottom: 24px;
    }
    .minilq.interchange_sticky .getUpdates {
      padding-bottom: 0;
    }
    #expand-contract .loop-qualification:has(#streeError) #reskin-internal-wrapper,
    #expand-contract .loop-qualification:has(#appartment-unit-errorText) #reskin-internal-wrapper {
      padding-bottom: 0px;
    }
    #expand-contract .loop-qualification:has(#streeError) #geoLocationText,
    #expand-contract .loop-qualification:has(#streeError) #streetInputText,
    #expand-contract .loop-qualification:has(#appartment-unit-errorText) #appartment-unit {
      /* padding-top: 24px; */
    }
    #expand-contract .unitErrorStreet {
      /* padding-top: 16px !important; */
    }
    #expand-contract .loop-qualification #streeError,
    #expand-contract .loop-qualification #appartment-unit-errorText {
      padding-bottom: 16px;
      line-height: normal;
      padding-top: 0.25em;
    }
    #expand-contract .unitInputError {
      padding-bottom: 40px !important;
    }
    .minilq .sticyBarCtaDiv {
      /* padding-top: 36px; */
      /* padding-bottom: 40px; */
    }
    #lqw-modal-container .loader {
      right: initial;
      bottom: initial;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .minilq .sticyBarCtaDiv {
      align-items: flex-start;
    }
  }
  @media (max-width: 767px) {
    &#lq20 div:has(#lqw-modal-container) {
      padding: 0px 0px 0px 0px;
    }
    &#lq20 #lqw-modal-container {
      padding: 32px 16px 0px 16px !important;
    }
    #continue-cta {
      margin-bottom: 32px;
      margin-top: 32px;
    }
    .minilq.interchange_sticky #left-content #ae-street-wrapper {
      width: auto;
    }
    .minilq.interchange_sticky #left-content #ae-edit-wrapper {
      float: left;
      margin-left: 12px;
    }
  }

  #reskin-internal-wrapper > div:empty {
    border: 0 !important;
  }
  /* @media (max-width: 1023px) { */
  #appartment-unit-errorText,
  #streeError {
    padding-top: 8px !important;
    display: inline-block;
    height: auto;
  }
  /* } */
  /* fed css */
  #feature-hero-tiles {
    position: relative !important;
    z-index: 81 !important;
  }

  div[class*='PrivacyPolicy'] p {
    font-size: 0.6875rem;
    font-weight: 400;
    line-height: 1rem;
    font-family: 'Verizon-NHG-eTX, Helvetica, Arial, Sans-serif';
    color: #000000;
    margin: 0;
    text-decoration: none;
  }

  div[class*='RightSection-'] p {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    font-family: Verizon-NHG-eTX, Helvetica, Arial, sans-serif;
    color: rgb(0, 0, 0);
    margin: 0px;
    text-decoration: none;
  }
`;
