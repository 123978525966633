import React from 'react';
import { useSelector } from 'react-redux';
import MiniLQ from '../mini-lq';

const MiniLQStandAlone = (props) => {
  console.log('...props', props);
  const selector = useSelector((state) => state);
  const frameObj = {
    loopQualApp: selector.loopQualApp,
    loaderStatus: selector.loader.loaderStatus,
    customerStreetDetails: selector.addressLookup.customerStreetDetails,
    revisitAddress: selector.addressLookup.revisitAddress,
    streetInput: selector.addressLookup.streetInput,
    addressLookup: selector.addressLookup,
    unitInputError: selector.unitLookup.unitInputError,
    unitLookupDetails: selector.unitLookup,
    addressQualification: selector.addressQualification,
    modalOverlay: selector.modalOverlay,
    lqResult: selector.addressQualification.lqResult || {},
    multiServiceDetails: selector.multiServiceDetails,
    isLoggedIn: selector.loopQualApp.isLoggedIn,
    fiosDetails: selector.fiosDetails,
    moveDetails: selector.moveDetails,
    notify: selector.notify,
    bulkQualDetails: selector.bulkQualDetails,
    parserAttempt: selector.addressLookup.parserAttempt,
    sessionTimeout: selector.sessionTimeout,
  };
  return (
    <>
      <MiniLQ {...props} {...frameObj} />
    </>
  );
};

export default MiniLQStandAlone;
