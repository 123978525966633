import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { StyleSheetManager } from 'styled-components';
import { store, persistor } from '../../reducers/configure-store';
import { ShadowStyleWrapper } from './ShadowStyles';
import MiniLQStandAlone from './MiniLQStandAlone';
import ModalShadowContent from './ModalStandalone';

const ShadowWrapper = (props) => {
  const shadowRef = useRef(null);
  const domroot = useRef(null);

  useEffect(() => {
    if (shadowRef?.current) {
      const isMiniLQ = props?.shadowID === 'MiniLQ';
      const isModal = props?.shadowID === 'ModalOverlay';
      const isHomeLQ = props?.shadowID === 'HomeLQ';
      const shadowRoot = shadowRef.current.attachShadow({ mode: 'open' });
      const rootElement = document.createElement('div');
      // for here map
      const style = document.createElement('link');
      style.setAttribute('rel', 'stylesheet');
      style.setAttribute('href', 'https://js.api.here.com/v3/3.1/mapsjs-ui.css');
      shadowRoot.appendChild(style);
      shadowRoot.appendChild(rootElement);
      if (!window.shadowWrapper) {
        window.shadowWrapper = {};
      }
      if (isMiniLQ) {
        window.shadowWrapper.miniLQ = shadowRoot;
      }
      if (isModal) {
        window.shadowWrapper.modalOverlay = shadowRoot;
      }
      domroot.current = ReactDOM.createRoot(rootElement);
      domroot.current.render(
        <StyleSheetManager target={shadowRoot}>
          <ShadowStyleWrapper id={isModal ? 'lq20' : ''}>
            {isMiniLQ || isModal || isHomeLQ ? (
              <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                  {isMiniLQ ? <MiniLQStandAlone {...props} /> : isHomeLQ ? props.children : <ModalShadowContent {...props} />}
                </PersistGate>
              </Provider>
            ) : (
              props.children
            )}
          </ShadowStyleWrapper>
        </StyleSheetManager>,
      );
    }
  }, []);

  return <div id={props?.shadowID || ''} ref={shadowRef} />;
};

export default ShadowWrapper;

ShadowWrapper.propTypes = {
  children: PropTypes.any,
  shadowID: PropTypes.string,
};
